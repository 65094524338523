import React from 'react'
import styled from "styled-components";
import { Link } from "react-router-dom";
import logo from "../../assets/LindenLogo.svg";

const Brand = () => {
  return (
  	<div className="branding">
  	<Link to="/">
    	<Image src={logo} alt="Linden Cohousing" />Linden Internal Website
    </Link>
    </div>
  )
}

export default Brand

const Image = styled.img`
  height: 85%;
  margin: auto 0;
  align: middle;
`;