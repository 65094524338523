import React from 'react'
import ROUTES from '../../constants/routes';
import { AuthUserContext, isMember } from '../Session';
import Navigation from './Navigation'

const Navbar = (props) => {
  const authItems = [
    {'path': ROUTES.HOME,'label':'Home'},
    {'path': ROUTES.DOCS,'label':'Search'},
    {'path': ROUTES.FILES,'label':'Documents'},
    {'path': ROUTES.EVENTS,'label':'Calendar'},
    {'path': ROUTES.MEMBERS, 'label':'Directory'}
  ];
  const publicItems = [
    {'path': ROUTES.HOME,'label':'Home'},
    {'path': ROUTES.SIGN_IN,'label':'Sign In'}
  ]
  return (
    <AuthUserContext.Consumer>
      {user =>
        isMember(user) ? <Navigation items={authItems} navbarState={props.navbarState} handleNavbar={props.handleNavbar} /> 
                       : <Navigation items={publicItems} navbarState={props.navbarState} handleNavbar={props.handleNavbar}/>
      }
    </AuthUserContext.Consumer>
   )
};

export default Navbar;