import React, { Component } from 'react';
import algoliasearch from 'algoliasearch/lite';
import {
  InstantSearch,
  Hits,
  Snippet,
  SearchBox,
  Pagination,
  SortBy,
} from 'react-instantsearch-dom';
import PropTypes from 'prop-types';
import * as firebase from 'firebase';
import 'firebase/firestore';
import { FirestoreProvider, FirestoreCollection } from "@react-firebase/firestore";

const config = {
    apiKey: "AIzaSyCHBiLrN1SA2NRo7ixbmoiGE4EySxbV5dM",
	authDomain: "lindenapp-4bcaa.firebaseapp.com",
	databaseURL: "https://lindenapp-4bcaa.firebaseio.com",
	projectId: "lindenapp-4bcaa",
	storageBucket: "lindenapp-4bcaa.appspot.com",
	messagingSenderId: "517754607119",
	appId: "1:517754607119:web:1f9520f1f06d9aa650357a"	
};

const searchClient = algoliasearch(
  '7KIR8IYDFT',
  '31e359e46d5dc2069daa11c8c7c42708'
);

//const s = (a: any) => JSON.stringify(a, null, 2);

class Members extends Component {
  render() {
    return (
      <>
        <div className="container">
        <h2>Members</h2>
        <InstantSearch searchClient={searchClient} indexName="members">
            <div className="search-panel">
              <div className="search-panel__results">
                <ul className="whatsNew">
                <li className="topOfPageItem">
                <SearchBox
                  className="searchbox"
                  translations={{
                    placeholder: '',
                  }}
                />
                </li>
                <li className="topOfPageItem">
                <SortBy
				  defaultRefinement="members"
				  items={[
				    { value: 'members', label: 'By Name (A to Z)' },
				    { value: 'members_by_name_desc', label: 'By Name (Z to A)' },
				    { value: 'members_by_unit_asc', label: 'By Unit (1st to 3rd floor)' },
				    { value: 'members_by_unit_desc', label: 'By Unit (3rd to 1st floor)' },
				  ]}
				/>
                </li>
                <li className="topOfPageItem">
                  <article>Send updates to <a target="_blank" href="mailto:robin.bechhofer@gmail.com">Robin robin.bechhofer@gmail.com</a></article>
                  <article><a target="_blank" href="https://drive.google.com/file/d/1JDZfZ4g51IL6c6tPrXKPF48A_IhqF3Qw/view?usp=sharing">Linden Members - By Unit</a></article>
                  <article><a target="_blank" href="https://docs.google.com/spreadsheets/d/1aJc_rtNqGtmqYERKINluc7VVVFmhxi8wasOc2c4xV1k">Members Spreadsheet</a></article>
                </li>
                </ul>
                <Hits hitComponent={Hit} />
              </div>
            </div>
        </InstantSearch>
        </div>
      </> 
    )
  }
}

function Hit(props) {
  var member = props.hit;
  return (
    <article>
	    <h3>{member['Name']}</h3>
	    <p>Unit: {member['Unit #']} </p>
	    <p>Ph: {member['Preferred Phone']}</p>
	    <p>Email: {member['Email Address']}</p>
	</article>
    );
}

Hit.propTypes = {
  hit: PropTypes.object.isRequired,
};

export default Members
