import React, { Component } from 'react';
import * as firebase from 'firebase';
import 'firebase/firestore';
import { FirestoreProvider, FirestoreCollection } from "@react-firebase/firestore";
import algoliasearch from 'algoliasearch/lite';
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import {
  InstantSearch,
  Hits,
  Snippet,
  SearchBox,
  Pagination,
  RefinementList
} from 'react-instantsearch-dom';
import PropTypes from 'prop-types';

const processString = require('react-process-string');


const config = {
  apiKey: "AIzaSyCHBiLrN1SA2NRo7ixbmoiGE4EySxbV5dM",
	authDomain: "lindenapp-4bcaa.firebaseapp.com",
	databaseURL: "https://lindenapp-4bcaa.firebaseio.com",
	projectId: "lindenapp-4bcaa",
	storageBucket: "lindenapp-4bcaa.appspot.com",
	messagingSenderId: "517754607119",
	appId: "1:517754607119:web:1f9520f1f06d9aa650357a"	
};

const searchClient = algoliasearch(
  '7KIR8IYDFT',
  '31e359e46d5dc2069daa11c8c7c42708'
);


const months = {
	'01':'JAN','02':'FEB','03':'MAR','04':'APR','05':'MAY','06':'JUN','07':'JUL','08':'AUG','09':'SEP','10':'OCT','11':'NOV','12':'DEC'
}

class Events extends Component {
  render() {
    return (
      <>
        <div className="container">
        <h2>Events</h2>
        <InstantSearch searchClient={searchClient} indexName="events">
            <div className="search-panel">
              <div className="search-panel__results">
                <ul className="whatsNew">
                <li className="topOfPageItem">
                <SearchBox
                  className="searchbox"
                  translations={{
                    placeholder: '',
                  }}
                />
                </li>
                <li className="topOfPageItem">
                <h3>Filter By Calendar</h3>
                <RefinementList attribute="cal" />
                </li>
                <li className="topOfPageItem">
                  <article><a target="_blank" href="https://docs.google.com/forms/d/e/1FAIpQLSd8JExyjIz6w2GR-z8MO_8oBz0ujywV8kLApZpaU-kZLhSmDA/viewform">Reservation Form</a></article>
                  <article><a target="_blank" href="https://drive.google.com/file/d/1MYhmjR0BEeELxbNDTW5g48nPrA8dd8KT/view?usp=sharing">Monthly Meeting Schedule</a></article>
                </li>
                </ul>
                <Hits hitComponent={Hit} />
              </div>
            </div>
        </InstantSearch>
        </div>
      </> 
    )
  }
}

function Hit(props) {
  var event = props.hit;
  let linksconfig = [{
            regex: /(http|https):\/\/(\S+)\.([a-z]{2,}?)(.*?)( |\,|$|\.)/gim,
            fn: (key, result) => {
				let domain = result[2];
				if (domain.includes('zoom', 0)) {
					return <span key={key} type='B'>
                          <a target="_blank" href={`${result[1]}://${result[2]}.${result[3]}${result[4]}`}> Zoom Link</a><br/>{result[5]}
                       </span>;
                } else {
					return <span key={key} type='C'>
                          <a target="_blank" href={`${result[1]}://${result[2]}.${result[3]}${result[4]}`}> {result[2]}.{result[3]}{result[4]}</a>{result[5]}
                       </span>
				}
			}
        }, {
            regex: /(\S+)\.([a-z]{2,}?)(.*?)( |\,|$|\.)/gim,
            fn: (key, result) => {
				let domain = result[1];
				if (domain.includes('zoom', 0)) {
					return <span key={key} type='D'>
                            <a target="_blank" href={`${result[1]}.${result[2]}${result[3]}`}> Zoom Link</a><br/>{result[4]}
                    </span>
                } else {
					return <span key={key} type='E'>
                            <a target="_blank" href={`${result[1]}.${result[2]}${result[3]}`}> {result[1]}.{result[2]}{result[3]}</a>{result[4]}
                    </span>
				}
            }
        }];
  let processed = '';
  if (event['desc']) {
	var descContent = event['desc'].replace(/<[^>]+>/g, '');
	processed = processString(linksconfig)(descContent);
  }
  return (
    <article>
      <div className={event['cal']}><p>{months[event['month']]} {event['day']}</p></div>
      <h4>{event['summary']}</h4>
      <p>{event['time']}</p>
      <p>{event['loc']}</p>
      <p>Calendar: {event['cal']}</p>
      <div>{processed}</div>
    </article>
    );
}

Hit.propTypes = {
  hit: PropTypes.object.isRequired,
};

export default Events
