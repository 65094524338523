import { createGlobalStyle } from 'styled-components';

const GlobalStyles = createGlobalStyle`

  @import url('https://fonts.googleapis.com/css?family=Montserrat:400,600&display=swap');;

  *,
  *::after,
  *::before {
    margin: 0px;
    padding: 0px;
    box-sizing: inherit;
  }

  html {
    font-size: .8em;
  }

  body {
    box-sizing: border-box;
    font-family: 'Montserrat', sans-serif;
  }

  .c-modal-cover {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 10; // This must be at a higher index to the rest of your page content
    transform: translateZ(0);
    background-color: rgba(#000, 0.15);
  }

.c-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 2.5em 1.5em 1.5em 1.5em;
  background-color: #FFFFFF;
  box-shadow: 0 0 10px 3px rgba(0, 0, 0, 0.1);
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;

  @media screen and (min-width: 500px) {
    left: 50%;
    top: 50%;
    height: auto;
    transform: translate(-50%, -50%);
    max-width: 30em;
    max-height: calc(100% - 1em);
  }
}

.c-modal__close {
  position: absolute;
  top: 0;
  right: 0;
  padding: .5em;
  line-height: 1;
  background: #f6f6f7;
  border: 0;
  box-shadow: 0;
  cursor: pointer;
}

.c-modal__close-icon {
  width: 25px;
  height: 25px;
  padding-right: 10px;
  fill: transparent;
  stroke: black;
  stroke-linecap: round;
  stroke-width: 2;
}

.c-modal__body {
  padding-top: .25em;
}

.u-hide-visually {
  border: 0 !important;
  clip: rect(0 0 0 0) !important;
  height: 1px !important;
  margin: -1px !important;
  overflow: hidden !important;
  padding: 0 !important;
  position: absolute !important;
  width: 1px !important;
  white-space: nowrap !important;
}

.modalConfirmButtons {
  padding-top: 10px;
  text-align: center;
}

.modalConfirmButtons button {
  padding: 1rem;
  margin: 1rem;
}


  #site-title {
    font-style: italic;
  }

  .container {
  max-width: 1200px;
  margin: 1rem 0 0 1rem;
  }

.search-panel {
  display: flex;
}

.search-panel__filters {
  flex: 1;
}

.search-panel__results {
  flex: 3;
}

.searchbox {
  margin-bottom: 2rem;
}

.pagination {
  margin: 2rem auto;
  text-align: center;
}

.pagination ul {
  display: inline-block;
  text-align: center;
}

.pagination li {
  float: left;
  padding: 4px 8px;
  list-style: none;
}

li.ais-Pagination-item.ais-Pagination-item--selected.ais-Pagination-item--page > a {
  border-radius:5px;
  background-color:#003300;
  color: white;
}

.pagination a {
  float: left; 
  padding: 8px 16px; 
  border-radius: 5px;
  text-decoration: None;
}

.pagination a.active {
  border-radius: 5px;
}

ul.ais-Hits-list {
  max-width: 1200px;
  margin: 0 auto;
  display: grid;
  grid-gap: 1rem;
  list-style-type: none;
}

li.ais-Hits-item {
  margin-top: 1rem;
  padding: 1rem;
  width: auto;
  max-width: 300px;
  border: 1px solid #c4c8d8;
  border-radius: 4px;
  box-shadow: 0 2px 5px 0 #e3e5ec;
}

@media (min-width: 800px) {
  .ais-Hits-list { grid-template-columns: repeat(4, 1fr); }
}

ul.whatsNew {
  max-width: 1200px;
  margin: 0 auto;
  display: grid;
  grid-gap: 1rem;
}

li.whatsNewItem {
  margin-top: 1rem;
  margin-left: 1rem;
  padding: 1rem;
  width: auto;
  max-width: 300px;
  border: 1px solid #c4c8d8;
  border-radius: 4px;
  box-shadow: 0 2px 5px 0 #e3e5ec;
  list-style-type: none;
}

ul.topOfPage {
  max-width: 1200px;
  margin: 0 auto;
  display: grid;
  grid-gap: 1rem;
}

li.topOfPageItem {
  margin-top: .5rem;
  margin-left: .5rem;
  padding: .5rem;
  width: auto;
  max-width: 300px;
  list-style-type: none;
}

.ais-RefinementList-list {
  list-style-type: none;
}

li.whatsNewItem h4 {
  padding-bottom: 1em;
}

@media (min-width: 800px) {
  .whatsNew { grid-template-columns: repeat(4, 1fr); }
}
 
img.hitIcon {
  width: 25px;
  padding-right: 10px;
}

.ais-SearchBox-input {
  padding: .3rem 2rem;
}

.page-title {
  font-size: 18px;
  margin-top: 1rem;
  margin-left: 1em;
  clear: both;
  width: auto;
  max-width: 400px;
  list-style-type: none;
}

a:link {
  color: #12641A
}

.NavHeader a:link {
  color:white
}

.NavHeader a:link {
  text-decoration:none;
}

.branding a:link, .branding a:visited, .branding a:hover, .branding a:active {
  text-decoration: none;
  font-weight: bold;
  color: #303030;
}

.branding a {
  white-space: nowrap;
} 

div.LC-Social {
  height: 1.5em;
  margin-bottom: 1em;
  background-color: #fad980;
  font-weight: bold;
  text-align: center;
}

div.LC-Workshop {
  height: 1.5em;
  margin-bottom: 1em;
  background-color: #d8bbf0;
  font-weight: bold;
  text-align: center;
}

div.LC-Private-Events {
  height: 1.5em;
  margin-bottom: 1em;
  background-color: #f9dede;
  font-weight: bold;
  text-align: center;
}

div.LC-Meetings {
  height: 1.5em;
  margin-bottom: 1em;
  background-color: #94bfa2;
  font-weight: bold;
  text-align: center;
}

div.LC-Guest-Rooms {
  height: 1.5em;
  margin-bottom: 1em;
  background-color: #8ba6ca;
  font-weight: bold;
  text-align: center;
}

ul.filterMenu {
  max-width: 1200px;
  margin: 0 auto;
  display: grid;
  grid-gap: 1rem;
}

.filterSelect {
  clear: both;
  margin-top: 1rem;
  width: auto;
  max-width: 400px;
  list-style-type: none;
}

.LCHFooter img {
  margin-left: 20px;
}

fieldset.passwordForget {
  padding: 6px;
}

fieldset.passwordForget {
  padding: 6px;
}

fieldset.newUser {
  padding: 6px;
  width: 400px;
  margin-left: 8px;
}

`;

export default GlobalStyles;
