import React, { Component } from 'react';

class SignUpConfirm extends Component {
  render() {

    return (
      <>
       <div className="container">
        <h3>Welcome to the Linden Intranet!</h3>
        <p>Once you are assigned member rights you will be able to view all of the content on the site.</p>
       </div>
      </>
    )
  }
}


export default SignUpConfirm

