import PropTypes from 'prop-types'
import React from 'react'
import Modal from '../Modal'

class Detail extends React.Component {
  static propTypes = {
    file: PropTypes.shape({
      key: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      extension: PropTypes.string.isRequired,
      url: PropTypes.string,
    }).isRequired,
    close: PropTypes.func,
  }

  handleCloseClick = (event) => {
    if (event) {
      event.preventDefault()
    }
    this.props.close()
  }

  handleOpenURL = (url,event) => {
    if (event) {
      event.preventDefault()
    }
    this.props.close()
    window.open(url,'_blank')
  }

  render() {
    let name = this.props.file.key.split('/')
    name = name.length ? name[name.length - 1] : ''

    return (
      <Modal closeFunc={this.handleCloseClick}>
        <p>Do you want to open {this.props.file.name} in a new tab?</p>
        <div className="modalConfirmButtons"><button onClick={(e) => this.handleOpenURL(this.props.file.url,e)}>Yes</button> 
         <button onClick={this.handleCloseClick}>No</button></div>
      </Modal>
    )
  }
}

export default Detail
