import React from 'react';
import { AuthUserContext, hasEmail } from '../Session';
import { withFirebase } from '../Firebase';

const SignOutButton = ({ firebase }) => {
  return (
	 <AuthUserContext.Consumer>
      {user =>
        hasEmail(user) ? <button type="button" onClick={firebase.doSignOut}>Sign Out</button>
                       : <div />
      }
    </AuthUserContext.Consumer>
  
   )
};

export default withFirebase(SignOutButton);
