import React from 'react';
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import AuthUserContext from './context';
import { withFirebase } from '../Firebase';
import ROUTES from '../../constants/routes';

const withAuthorization = condition => Component => {
  class WithAuthorization extends React.Component {
    async getToken(user) {
       const idTokenResult = await user.getIdTokenResult();
       return idTokenResult;
    }
    componentDidMount() {
      this.listener = this.props.firebase.auth.onAuthStateChanged(
        authUser => {
          authUser
          ?  this.getToken(authUser).then((token) => {
              let user = {
                  'claims': token.claims,
                  'email': authUser.email
              };
              if (!condition(user)) { 
                  this.props.history.push(ROUTES.SIGN_IN);
              }
            })
          : this.props.history.push(ROUTES.SIGN_IN)
        },
      );
    }
    componentWillUnmount() {
      this.listener();
    }
    render() {
      return (
        <AuthUserContext.Consumer>
          {user =>
            condition(user) ? <Component {...this.props} /> : null
          }
        </AuthUserContext.Consumer>
      );
    }
  }
  return compose(
    withRouter,
    withFirebase,
  )(WithAuthorization);
};

export default withAuthorization;