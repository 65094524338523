import React, { Component } from 'react';
import {
  SearchkitManager,
  SearchkitProvider,
  SearchBox,
  Hits,
  HitItemProps
} from "searchkit"

const host = "http://157.245.252.119/es/linden-internal-files"

const searchkit = new SearchkitManager(host, {
  multipleSearchers:false, // defaults to false
  httpHeaders:{
  	'Access-Control-Allow-Headers':'*',
  	'Authorization': 'Bearer eyJhbGciOiJSUzI1NiIsImtpZCI6IjRlMjdmNWIwNjllYWQ4ZjliZWYxZDE0Y2M2Mjc5YmRmYWYzNGM1MWIiLCJ0eXAiOiJKV1QifQ.eyJtZW1iZXIiOnRydWUsImdyb3VwcyI6WyJtZWFscyIsIkRJTSIsIkRDRyJdLCJpc3MiOiJodHRwczovL3NlY3VyZXRva2VuLmdvb2dsZS5jb20vbGluZGVuLTdiODQ1IiwiYXVkIjoibGluZGVuLTdiODQ1IiwiYXV0aF90aW1lIjoxNTkxOTgyMTk4LCJ1c2VyX2lkIjoiNHNaUGNhSm1JQlNtNTdsUURGbjA4UVcwU0xmMiIsInN1YiI6IjRzWlBjYUptSUJTbTU3bFFERm4wOFFXMFNMZjIiLCJpYXQiOjE1OTE5ODIxOTgsImV4cCI6MTU5MTk4NTc5OCwiZW1haWwiOiJsaW5kZW5jb2hvdXNpbmdAZ21haWwuY29tIiwiZW1haWxfdmVyaWZpZWQiOnRydWUsImZpcmViYXNlIjp7ImlkZW50aXRpZXMiOnsiZW1haWwiOlsibGluZGVuY29ob3VzaW5nQGdtYWlsLmNvbSJdfSwic2lnbl9pbl9wcm92aWRlciI6InBhc3N3b3JkIn19.fWNz3QoZSxialfRkvovMMhyL_VjLg0Ayofyz85QG1ZSXB639LVI6Gydw9HAXwsWn5MUzpVRd2LYNzLbWmHwbNELw9hMWC0SxqEozxk2XTlGheYkMDuxIg4ddxagGfren-XzNcApcnvA7T5paJYFrThc9fNINn0t2pD_R9tihxCt5wCVkFzt5OktdTq45PosPAkqNJyuj-2HghA_LeiqPTm8DstZPUIJJnBM9jR5wmA9qCeQUdRoP70DsaDkVXAj2GDfk-MW8SHQ08UIpslVQCC2GZMpgBqXr50TCGxQooGttVjukijVv0VpzcoJX89JM9ckhALP3SHyPVflgBJaYXQ'
  }
})

class ESTest extends Component {
  render() {
    return (
    <SearchkitProvider searchkit={searchkit}>
        <div>
            <SearchBox/>
            <Hits/>
        </div>
    </SearchkitProvider>
	)
   }
}

export default ESTest

