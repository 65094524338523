import React, { Component }  from 'react';
import { BrowserRouter as Router, Switch, Route, Redirect  } from 'react-router-dom';
import Navbar from '../navbar/Navbar'
import Home from '../Home';
import Docs from '../Docs';
import Events from '../Events';
import Members from '../Members';
import SignInPage from '../SignIn';
import SignUpPage from '../SignUp';
import PasswordForget from '../PasswordForget';
import PasswordConfirm from '../PasswordConfirm';
import SignUpConfirm from '../SignUpConfirm';
import AccountPage from '../Account';
import Files from '../files';
import ESTest from '../ESTest';
import ROUTES from '../../constants/routes';
import { withAuthentication, AuthUserContext, isMember, hasEmail } from '../Session';

class App extends Component {
  state = {
    navbarOpen: false
  }
 
  handleNavbar = () => {
    this.setState({ navbarOpen: !this.state.navbarOpen });
  }

  render() {

    return (
     
  <Router>
    <div>
      <div className='NavHeader'>
      <Navbar 
        navbarState={this.state.navbarOpen} 
        handleNavbar={this.handleNavbar}
      />
      </div>

     	<hr />
      <AuthUserContext.Consumer>
      {user =>
        hasEmail(user) ? <UserMsg user={user} /> 
                       : <Redirect to='/Home' />
      }
      </AuthUserContext.Consumer>
      <Switch>
        <Route exact path={ROUTES.HOME}>
          <Home />
        </Route>
        <Route exact path={ROUTES.FILES}>
          <Files />
        </Route>
        <Route exact path={ROUTES.DOCS}>
          <Docs />
        </Route>
        <Route exact path={ROUTES.EVENTS}>
          <Events />
        </Route>
         <Route exact path={ROUTES.MEMBERS}>
          <Members />
        </Route>
    	  <Route path={ROUTES.SIGN_IN}> 
          <SignInPage />
        </Route>
        <Route path={ROUTES.SIGN_UP}>
          <SignUpPage />
        </Route>
        <Route path={ROUTES.SIGN_UP_CONFIRM}>
          <SignUpConfirm />
        </Route>
        <Route path={ROUTES.PASSWORD_FORGET}>
          <PasswordForget />
        </Route>
        <Route path={ROUTES.PASSWORD_CONFIRM}>
          <PasswordConfirm />
        </Route>
        <Route path={ROUTES.ACCOUNT}>
          <AccountPage />
        </Route>
        <Route path="/ESTest">
          <ESTest />
        </Route>
        <Route path="/">
          <Home />
        </Route>
      </Switch>
      <div className="LCHFooter">
        <img src="/images/algolia.png" alt="Search By Algolia" width="90px" />
      </div>
    </div>
  </Router>

)}};


function UserMsg(props) {
  const greeting = isMember(props.user) 
                    ? null 
                    : <fieldset className="newUser"><legend>New User</legend>
                      <i>Welcome to the Linden Intranet! If you are a Linden Cohousing Member, you should 
                      be assigned member rights within a couple of hours.</i></fieldset>;
  return <p>{greeting}</p>;
}

export default withAuthentication(App);
