import React from 'react'
import Moment from 'moment'
import './react-keyed-file-browser.css'
import { faFolder, faFolderOpen, faFilePdf } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { withFirebase } from '../Firebase';
import FileBrowser from 'react-keyed-file-browser'
import Detail from './Detail'

const config = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_DATABASE_URL,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
};


class Files extends React.Component {

  state = {
    navbarOpen: false,
    files: []
  }
    
  handleNavbar = () => {
    this.setState({ navbarOpen: !this.state.navbarOpen });
  }

  componentDidMount() {
    this.props.firebase.db.collection("filesBrowser")
      .get()
      .then(querySnapshot => {
        const data = querySnapshot.docs.map(doc => doc.data());
        this.setState({ files: data });
      });
  }
  

  render() {
    return (
    <>
      <div className="fileBrowser">
      <FileBrowser
        files={this.state.files}

       icons={{
          File: <i className="file" aria-hidden="true" />,
          Image: <i className="file-image" aria-hidden="true" />,
          PDF: <FontAwesomeIcon icon={faFilePdf} />,
          Folder: <FontAwesomeIcon icon={faFolder} />,
          FolderOpen: <FontAwesomeIcon icon={faFolderOpen} />,
          Loading: <i className="circle-notch spin" aria-hidden="true" />,
        }}

        detailRenderer={Detail}
      />
      <article>
        <ul>
          <li>Send minutes to <a href="mailto:robin.bechhofer@gmail.com">Robin robin.bechhofer@gmail.com</a></li>
          <li>For all other documents, go to <a href="https://docs.google.com/forms/d/e/1FAIpQLSdOfqU42DxS1Y_dkLLyseHIc3jH91nX3bQHeiDjMSmrepGXow/viewform">Internal Files Form.</a></li> 
        </ul>
        <br/>
      </article>
      </div>
    </>
    )
  }
}

export default withFirebase(Files);