import React, { Component } from 'react';

class PasswordConfirm extends Component {
  render() {

    return (
      <>
       <div className="container">
        <h3>Password Reset</h3>
        <p>Check you inbox for an email with a link to reset your password.</p>
       </div>
      </>
    )
  }
}


export default PasswordConfirm

