import React from 'react';
import algoliasearch from 'algoliasearch/lite';
import {
  InstantSearch,
  Hits,
  Snippet,
  SearchBox,
  Pagination,
  SortBy
} from 'react-instantsearch-dom';
import PropTypes from 'prop-types';
import folderIcon from '../../assets/folder.svg'
import docIcon from '../../assets/doc.svg'

const searchClient = algoliasearch(
  '7KIR8IYDFT',
  '31e359e46d5dc2069daa11c8c7c42708'
);


class Docs extends React.Component {
  state = {
    navbarOpen: false
  }

  handleNavbar = () => {
    this.setState({ navbarOpen: !this.state.navbarOpen });
  }

  render() {

    return (
      <>
       <div className="container">
       <h2>Fulltext Search</h2>
        <InstantSearch searchClient={searchClient} indexName="docs">
            <div className="search-panel">
              <div className="search-panel__results">
                <ul className="whatsNew">
                <li className="topOfPageItem"><SearchBox
                  className="searchbox"
                  translations={{
                    placeholder: '',
                  }}
                /></li>
                <li className="topOfPageItem"><SortBy
                  defaultRefinement="docs"
                  items={[
                    { value: 'docs', label: 'Most Recent First' },
                    { value: 'docs_oldest_first', label: 'Oldest First' },
                    { value: 'docs_name_desc', label: 'By Name (Z to A)' },
                    { value: 'docs_name_asc', label: 'By Name (A to Z)' }
                  ]}
                /></li>
                <li className="topOfPageItem">
                  <article>
                    <ul>
                    <li>Send minutes to <a href="mailto:robin.bechhofer@gmail.com">Robin robin.bechhofer@gmail.com</a></li>
                    <li>For all other documents, go to <a href="https://docs.google.com/forms/d/e/1FAIpQLSdOfqU42DxS1Y_dkLLyseHIc3jH91nX3bQHeiDjMSmrepGXow/viewform">Internal Files Form.</a></li> 
                    </ul>
                  </article>
                </li>
                </ul>
                <Hits hitComponent={Hit} />

                <div className="pagination">
                  <Pagination padding={1}/>
                </div>
              </div>
            </div>
        </InstantSearch>
       </div>
      </>
    )
  }
}

function Hit(props) {
  const hitType = props.hit.type;
  var hitIcon = docIcon;
  if (hitType === 'folder') {
    hitIcon = folderIcon
  }
  var docLink = null;
  if (props.hit.webViewLink) {
    docLink = props.hit.webViewLink
  } 
  return (
    <article>
      <p>
        <a href={docLink} target="_blank" rel="noopener noreferrer"><img className='hitIcon' src={hitIcon} align='top' alt='folder' width='20px'/>{props.hit.name}</a>
      </p>
      <Snippet hit={props.hit} attribute="content" />
    </article>
  );
}

Hit.propTypes = {
  hit: PropTypes.object.isRequired,
};

export default Docs

