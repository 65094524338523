
const ROUTES = {
	"HOME":"/Home",
	"DOCS":"/Docs",
	"EVENTS":"/Events",
	"MINUTES":"/minutes",
    "MEMBERS":"/Members",
    "FORMS":"/forms",
	"SIGN_IN":"/signin",
	"SIGN_UP":"/signup",
	"SIGN_UP_CONFIRM":"/signup_confirm",
	"PASSWORD_FORGET":"/forgotpassword",
	"PASSWORD_CONFIRM":"/password_confirm",
	"ACCOUNT":"/account",
	"FILES":"/files"
}
export default ROUTES
