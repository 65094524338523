import React from 'react';
import { PasswordForgetForm } from '../PasswordForget';
import PasswordChangeForm from '../PasswordChange';
import { AuthUserContext, withAuthorization, isMember } from '../Session';

const AccountPage = () => (
	<AuthUserContext.Consumer>
    {user => (
      <div>
        <h1>Email: {user.email}</h1>
        <PasswordForgetForm />
        <PasswordChangeForm />
      </div>
    )}
    </AuthUserContext.Consumer>
); 

const condition = user => isMember(user);
//const condition = user => hasGroup(user,'meals');
export default withAuthorization(condition)(AccountPage);