import React from 'react';
import AuthUserContext from './context';
import { withFirebase } from '../Firebase';

const withAuthentication = Component => {
  class WithAuthentication extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        user: {
          email: null,
          claims: null,
          jwt: null
        }
      };
    }
    async getToken(user) {
       const idTokenResult = await user.getIdTokenResult();
       return idTokenResult;
    }
    setUnAuth() {
      this.setState( { user: {'claims': null,'email': null} });
      this.forceUpdate();
    }
    componentDidMount() {
      this.listener = this.props.firebase.auth.onAuthStateChanged(
        authUser => {
          authUser
          ?  this.getToken(authUser).then((token) => {
              let user = {
                  'claims': token.claims,
                  'email': authUser.email,
                  'jwt': token.token
              };
              this.setState({ user })
            })
          : this.setUnAuth()
        },
      );
    }
    componentWillUnmount() {
      this.listener();
    }
    render() {
      return (
        <AuthUserContext.Provider value={this.state.user}>
          <Component {...this.props} />
        </AuthUserContext.Provider>
      );
    }
  }
  return withFirebase(WithAuthentication);
};

export default withAuthentication;