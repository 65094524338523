import React, { Component } from 'react';
import { withFirebase } from '../Firebase';

class Home extends Component {
  
  state = {
    navbarOpen: false,
    articles: []
  }
    
  handleNavbar = () => {
    this.setState({ navbarOpen: !this.state.navbarOpen });
  }

  componentDidMount() {
    this.props.firebase.db.collection("content")
      .get()
      .then(querySnapshot => {
        const data = querySnapshot.docs.map(doc => doc.data());
        this.setState({ articles: data });
      });
  }


  render() {

    return (
      <>
       <div className="container">
        <ul className="whatsNew">
          {this.state.articles.map((article, index) => (
            <Item key={index} article={article} />
          ))}
        </ul>
       </div>
      </>
    )
  }
}

function ArticleImage({ url,link,altText }) {
  if (link) {
    return (
      <a href={link}><img width="250px" src={url} alt={altText} /></a>
    )
  } else {
    return (
      <img width="250px" src={url} alt={altText} />
    )
  }
}

function Item({ article }) {
  return (
  <li className="whatsNewItem">
    <h4>{article['Title']}</h4>
    <ArticleImage url={article['Image']} link={article['ImageLink']} altText={article['ImageAltText']} />
    <div className="articleBody">
      <div dangerouslySetInnerHTML={{ __html: article['Body'] }} />
    </div>
  </li>
  )
}

export default withFirebase(Home);

