import React from 'react'
import styled from "styled-components";
import { useSpring, animated, config } from "react-spring";
import Brand from "./Brand";
import BurgerMenu from "./BurgerMenu";
import CollapseMenu from "./CollapseMenu";
import SignOutButton from "../SignOut"
import { Link } from 'react-router-dom';


const Navigation = (props) => {
  const barAnimation = useSpring({
    from: { transform: 'translate3d(0, -10rem, 0)' },
    transform: 'translate3d(0, 0, 0)',
  });
  const linkAnimation = useSpring({
    from: { transform: 'translate3d(0, 30px, 0)', opacity: 0 },
    to: { transform: 'translate3d(0, 0, 0)', opacity: 1 },
    delay: 800,
    config: config.wobbly,
  });
  let links = [];
  for(let i = 0; i < props.items.length; i++) {
    links.push(
      <Link key={i} to={props.items[i]['path']}>{props.items[i]['label']}</Link>
    );
  }
  return (
    <>
    <NavBar style={barAnimation}>
        <FlexContainer>
          <BurgerWrapper>
              <BurgerMenu
                navbarState={props.navbarState} 
                handleNavbar={props.handleNavbar}
              />
            </BurgerWrapper>
          <Brand />
          <h3>{props.title}</h3>
          <NavLinks style={linkAnimation}>
            {links}
          </NavLinks>
          <SignOutButton />
        </FlexContainer>
    </NavBar>
    <CollapseMenu 
        navbarState={props.navbarState} 
        handleNavbar={props.handleNavbar}
        items={props.items}
    />
    </>
  )
};

export default Navigation;

const NavBar = styled(animated.nav)`
  min-height: 50px;
  padding: 0.5rem 1rem;
  background-image: linear-gradient(to right, #ffffff, #003300 );
  z-index: 1;
  font-size: 1.4rem;
`;

const FlexContainer = styled.div`
  max-width: 120rem;
  display: flex;
  margin: auto;
  padding: 0 2rem;;
  justify-content: space-between;
  height: 5rem;
`;

const NavLinks = styled(animated.ul)`
  justify-self: end;
  list-style-type: none;
  margin: auto 0;
  
  & a {
    color: #ffffff;
    text-transform: uppercase;
    font-weight: 600;
    border-bottom: 1px solid transparent;
    margin: 0 1.5rem;
    transition: all 300ms linear 0s;
    text-decoration: none;
    cursor: pointer;

    &:hover {
      color: #ffffff;
      border-bottom: 1px solid #fdcb6e;
    }

    @media (max-width: 768px) {
      display: none;
    }
  }
`;
 
const BurgerWrapper = styled.div`
  margin: auto 0;

  @media (min-width: 769px) {
    display: none;
  }
`;